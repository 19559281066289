import Swup from "swup";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";
import SwupMorphPlugin from "swup-morph-plugin";

export const newsPattern = ["/(en/news|news)(\\/?)", "/(en/news|news)(\\/?)\\?page=:num(\\d+)"];
export const sailboatPattern = "/(en/sailboats|segelboote)/:sailboat";

export default new Swup({
    containers: ["#main"],
    plugins: [
        new SwupMorphPlugin({
            containers: ["#localeSwitch", "#sailboatNav"],
        }),
        new SwupFragmentPlugin({
            rules: [
                {
                    from: sailboatPattern,
                    to: sailboatPattern,
                    containers: ["#sailboatEntry"],
                },
                {
                    from: newsPattern,
                    to: newsPattern,
                    containers: ["#newsGrid", "#loadMoreNews"],
                    scroll: '#firstNewsOnPage',
                },
            ],
        }),
        // The scripts plugin is needed to run the scripts included with formie forms
        new SwupScriptsPlugin({
            head: false,
            body: true,
        }),
        new SwupProgressPlugin({ delay: 500 }),
        new SwupPreloadPlugin(),
    ],
});
