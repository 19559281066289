export default () => ({
    expanded: false,
    success: false,
    formEl: null,

    init() {
        this.formEl = this.$el.querySelector('#newsletterSignup');
        this.formEl.addEventListener('onAfterFormieSubmit', (e) => {
            this.expanded = false;
            this.success = true;
        });

        setTimeout(() => {
            if (window.location.hash === '#newsletter') {
                this.expanded = true;
            }
        }, 300);

        this.$watch('expanded', () => {
            this.autofocus();
            if(!this.expanded){
                history.replaceState("", "", window.location.pathname);
            }
        });
    },

    expand() {
        this.expanded = true;
        this.autofocus();
    },

    autofocus() {
        if(this.expanded) {
            setTimeout(() => {
                this.$focus.focus(this.$el.querySelector('form input[type=text]'))
            }, 300)
        }
    }
});
